//
//  Content
//

@use 'sass:map';

.content {
	background: url('../../../assets/primaryBg.png') no-repeat top center;
	background-size: 100% auto;

	@include padding(map.get($content, padding-y) map.get($content, padding-x));
	padding-bottom: 32px;

	display: flex;
	flex: 1 0 auto;
	max-width: 1600px;
	// margin: 0 auto;

	// Switch to mobile background when below $aside.mobile-breakpoint
	@include media-breakpoint-down(map.get($aside, mobile-breakpoint)) {
		background: url('../../../assets/primaryMobileBg.png') no-repeat top center;
		background-size: 100% auto;
	}

	.header+& {
		@include media-breakpoint-down(map.get($aside, mobile-breakpoint)) {
			padding-top: map-deep-get($header, mobile, height);
		}
	}
}